import React from "react"

const ChangeQuanityDisiable = () => {
  return (
    <div className="h-[60px] cursor-no-drop bg-black-8 w-[100px] flex justify-center items-center">
      －
    </div>
  )
}

export default ChangeQuanityDisiable
