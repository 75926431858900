import React from "react"
import Topbar from "../../../components/organisms/topbar"
import SettingManagement from "../../../customComponents/inquiryManagement/detailContent/settingManagement"
import CustomLayout from "../../../Layouts/Layout"

const index = ({ location }) => {
  // console.log(location)
  return (
    <div>
      <Topbar />
      <CustomLayout
        selectedPage={
          <SettingManagement
            orderId={location?.state?.id}
            customer_id={location?.state?.customer_id}
          />
        }
      />
    </div>
  )
}

export default index
