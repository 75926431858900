import React, { memo, useContext, useEffect } from "react"
import { SettingContex } from "."
import { onKeyPressNumber } from "../../../../customUtil/global"

const ChangeQuanityStep = (props) => {
  const { setOrderVariant } = useContext(SettingContex)

  const handleOnUpCount = () => {
    if (props.count < props.inventoryQuantity) {
      props.setToggleError(false)
      props.setCount((prev) => prev + 1)
      setOrderVariant((prev) => {
        return prev.some((item) => item.variant_id === props.idVariant)
          ? prev.map((item) => {
              if (item.variant_id === props.idVariant) {
                return {
                  ...item,
                  max_quantity: Number(props.count + 1),
                }
              }
              return item
            })
          : [
              ...prev,
              {
                variant_id: props.idVariant,
                max_quantity: Number(props.count + 1),
              },
            ]
      })
    } else {
      props.setToggleError(true)
    }
  }

  const handleOnDownCount = () => {
    if (props.count > 0) {
      props.setToggleError(!(props.count - 1 <= props.inventoryQuantity))
      props.setCount((prev) => prev - 1)
      if (props.count > 1) {
        setOrderVariant((prev) => {
          return prev.some((item) => item.variant_id === props.idVariant)
            ? prev.map((item) => {
                if (item.variant_id === props.idVariant) {
                  return {
                    ...item,
                    max_quantity: Number(props.count - 1),
                  }
                }
                return item
              })
            : [
                ...prev,
                {
                  variant_id: props.idVariant,
                  max_quantity: Number(props.count - 1),
                },
              ]
        })
      } else {
        setOrderVariant((prev) =>
          prev.filter((item) => item.variant_id !== props.idVariant)
        )
      }
    }
  }

  return (
    <div className={`flex ${props.height}`}>
      <button
        className={`w-[22px] text-xl ${props.height} 
       border hover:border-red-500 hover:text-primary-90
       ${
         props.count
           ? "bg-pink-1 text-primary-90"
           : "text-[#c4c4c4] bg-[#F0F0F0]"
       }`}
        onClick={handleOnDownCount}
      >
        -
      </button>
      <input
        className={`w-[56px] outline-none text-center ${
          props.count ? "text-primary-90" : ""
        }`}
        value={props.count}
        onKeyPress={onKeyPressNumber}
        onBlur={(e) => {
          if (e.target.value) {
            setOrderVariant((prev) => {
              return prev.some((item) => item.variant_id === props.idVariant)
                ? prev.map((item) => {
                    if (item.variant_id === props.idVariant) {
                      return {
                        ...item,
                        max_quantity: Number(e.target.value),
                      }
                    }
                    return item
                  })
                : [
                    ...prev,
                    {
                      variant_id: props.idVariant,
                      max_quantity: Number(e.target.value),
                    },
                  ]
            })
          } else {
            setOrderVariant((prev) =>
              prev.filter((item) => item.variant_id !== props.idVariant)
            )
            props.setCount(0)
          }
        }}
        onChange={(e) => {
          const newNumber = e.target.value
          if (!isNaN(+newNumber)) {
            if (+newNumber <= props.inventoryQuantity) {
              props.setToggleError(false)
              props.setCount(+newNumber)
              return
            } else {
              props.setToggleError(true)
              return
            }
          }
        }}
      />
      <button
        className={`w-[22px] text-xl ${props.height}
      border hover:border-primary-90 hover:text-primary-90
      ${
        props.count
          ? "bg-pink-1 text-primary-90"
          : "text-[#c4c4c4] bg-[#F0F0F0]"
      }`}
        onClick={handleOnUpCount}
      >
        +
      </button>
    </div>
  )
}

export default memo(ChangeQuanityStep)
