import {
  Breadcrumb,
  Pagination,
  Typography,
  Table,
  Row,
  Button,
  Image,
} from "antd"
import { Link, navigate } from "gatsby"
import React, { createContext, useEffect, useMemo, useState } from "react"
import { getProduct, productNegotiate } from "../../../../services/apiProduct"
import { itemRender } from "../../../salesManagementy/Shared"
import SettingVariant from "./SettingVariant"
import "./style.scss"

const { Title } = Typography

export const SettingContex = createContext()

const SettingManagement = ({ orderId, customer_id }) => {
  const pageSize = useMemo(() => 10, [])
  const [products, setProducts] = useState({ data: [], total: 0 })
  const [page, setPage] = useState({
    order: { limit: pageSize, offset: 0 },
    current: 1,
  })
  const [loading, setLoading] = useState([false])
  const [orderVariant, setOrderVariant] = useState([])

  const columns = [
    {
      title: "商品画像",
      width: 100,
      dataIndex: "thumbnail",
      key: "thumbnail",
      render: (text) => (
        <div>
          <Image src={text} alt="" className="h-[35px] w-[60px] object-cover" />
        </div>
      ),
    },
    {
      title: "カテゴリ（大）",
      width: 140,
      dataIndex: "category_large",
      key: "category_large",
      render: (text) => (
        <div className="text-black-5 text-13 break-all">{text}</div>
      ),
    },
    {
      title: "カテゴリ（中）",
      width: 140,
      dataIndex: "category_middle",
      key: "category_middle",
      render: (text) => (
        <div className="text-black-5 text-13 break-all">{text}</div>
      ),
    },
    {
      title: "カテゴリ（小）",
      width: 140,
      dataIndex: "category_small",
      key: "category_small",
      render: (text) => (
        <div className="text-black-5 text-13 break-all">{text}</div>
      ),
    },
    {
      title: "商品名",
      width: 226,
      dataIndex: "title",
      key: "title",
      render: (text) => (
        <div className="text-black-5 text-13 break-all">{text}</div>
      ),
    },
    {
      title: <span className="text-center w-full">価格</span>,
      dataIndex: "price",
      key: "price",
      render: (price) => (
        <Row>
          <SettingVariant title={"最小ロット"} price={price.small[0]} />
          <SettingVariant title={"中ロット"} price={price.medium[0]} />
          <SettingVariant title={"大ロット"} price={price.large[0]} />
        </Row>
      ),
    },
  ]

  useEffect(() => {
    ;(async function () {
      try {
        setLoading(true)
        const { data } = await getProduct(page.order)
        setLoading(false)
        const dataProduct = data.products?.map((product) => ({
          key: product?.id,
          thumbnail: product?.thumbnail,
          category_large: product?.collectionMax
            ? product?.collectionMax?.title
            : "",
          category_small: product?.collection ? product?.collection?.title : "",
          category_middle: product?.collectionMedium
            ? product?.collectionMedium?.title
            : "",
          title: product?.title,
          price: {
            small: product.variants.filter((variant) => variant.weight === 0),
            medium: product.variants.filter((variant) => variant.weight === 1),
            large: product.variants.filter((variant) => variant.weight === 2),
          },
        }))
        setProducts({
          data: dataProduct,
          total: data.count,
        })
      } catch (error) {
        console.log(error)
      }
    })()
  }, [page.order])

  console.log(orderVariant)

  const onPagenationChange = (page, pageSize) => {
    const offset = (page - 1) * pageSize
    setPage({
      order: { limit: pageSize, offset: offset },
      current: page,
    })
  }

  const handleTableChange = (newPagination, filters, sorter) => {
    const offset = (newPagination.current - 1) * newPagination.pageSize
    setPage({
      order: { limit: pageSize, offset: offset },
      current: newPagination.current,
    })
  }

  const data = {
    orderVariant,
    setOrderVariant,
  }

  return (
    <SettingContex.Provider value={data}>
      <Breadcrumb separator={">"}>
        <Breadcrumb.Item>
          <Link to="/home">HOME</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/inquiry-management">販売管理</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/inquiry-management/setting-management">
            商品問い合わせ詳細
          </Link>
        </Breadcrumb.Item>
      </Breadcrumb>
      <Title
        className="!font-notoSans !font-medium !text-black-5 !text-xlarge !leading-8 mt-11"
        level={3}
      >
        商品追加
      </Title>
      <div className="w-full text-right">
        <Pagination
          itemRender={itemRender}
          total={products.total}
          current={page.current}
          pageSize={pageSize}
          showSizeChanger={false}
          onChange={onPagenationChange}
          showTotal={(total, range) => (
            <div>
              {range[0]}~{range[1]} 件 表示 &nbsp;&nbsp;&nbsp; (全
              {total}件)
            </div>
          )}
        />
      </div>
      <div className="customTable">
        <Table
          rowClassName={(record, index) => "hover:!bg-[#FFF5F7]"}
          dataSource={products.data}
          scroll={{ x: 1300 }}
          columns={columns}
          loading={loading}
          onChange={handleTableChange}
          locale={{
            emptyText: "検索結果は０です。",
          }}
          pagination={{
            total: products.total,
            current: page.current,
            pageSize: pageSize,
            itemRender: itemRender,
            showSizeChanger: false,
            showTotal: (total, range) => (
              <div>
                {range[0]}~{range[1]} 件 表示 &nbsp;&nbsp;&nbsp; (全
                {total}件)
              </div>
            ),
          }}
        />
      </div>
      <div className="mt-[76px] text-center">
        <Button
          className="!bg-[#E4E8EB] border border-[#E4E8EB] text-[13px] text-black-5"
          onClick={() => navigate(-1)}
        >
          お問合せページに戻る
        </Button>
        {orderVariant.length > 0 && (
          <Button
            className="ml-[10px]"
            type="primary"
            onClick={() => {
              ;(async function () {
                console.log(orderVariant)
                const res = await productNegotiate({
                  prices: orderVariant,
                  customer_id: customer_id,
                })
                navigate(-1)
                // console.log(res)
              })()
            }}
          >
            商品問い合わせリストに追加する
          </Button>
        )}
      </div>
    </SettingContex.Provider>
  )
}

export default SettingManagement
